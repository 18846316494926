import "/src/assets/main.css"
import { createApp } from "/node_modules/.vite/deps/vue.js?v=b5bb6c60";
import App from "/src/App.vue";
import pb from "/src/composables/usePocketbase.js";
import { createRouter, createWebHistory  } from "/node_modules/.vite/deps/vue-router.js?v=15a97cb2";
import routes from "/src/routes.js";

const app = createApp(App);

const router = createRouter({
  history: createWebHistory(),
  routes: routes, 
});

router.beforeEach((to, from, next) => {
  if (to.meta.reqAuth && !pb.authStore.isValid) {
      router.push("/");
      return false
  } else {
    next()
  }
  return true
})

app.use(router);

app.mount('#app');